<template>

  <!-- <swiper
    class="swiper-multiple"
    :options="swiperOptions"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    style="width: 14.0rem; justify-content: center;"
  >

    <swiper-slide
      v-for="item in filteredItems"
      :key="item.header || item.title"
    >
      <component
        :is="resolveSportComponent(item)"
        :item="item"
      />
    </swiper-slide>
  </swiper> -->

  <ul
    id="main-menu-navigation"
    class="nav navbar-nav"
  >
    <component
      :is="resolveSportComponent(item)"
      v-for="item in filteredItems"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'

// // import style
// import 'swiper/swiper-bundle.min.css'

import HorizontalSportMenuHeaderLink from '../horizontal-sport-menu-header-link/HorizontalSportMenuHeaderLink.vue'
import HorizontalSportMenuHeaderGroup from '../horizontal-sport-menu-header-group/HorizontalSportMenuHeaderGroup.vue'

export default {
  components: {
    HorizontalSportMenuHeaderLink,
    HorizontalSportMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    // const swiperOptions = {
    //   slidesPerView: 8,
    //   spaceBetween: 10,
    //   pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    //   },
    // }

    const resolveSportComponent = item => {
      if (item.children) return 'horizontal-sport-menu-header-group'
      return 'horizontal-sport-menu-header-link'
    }

    // Only include items with an icon and icon source that is not empty.
    const filteredItems = computed(() => props.items.filter(item => item.icon !== undefined && item.iconSource !== undefined && item.iconSource !== ''))

    return {
      resolveSportComponent,
      filteredItems,
      // swiperOptions,
    }
  },
}
</script>
