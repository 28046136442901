<template>
  <div
    class="deals d-block"
    :class="{'open': isDealsOpen}"
  >
    <!-- Toggler -->
    <b-link
      class="deals-toggle d-flex align-items-center justify-content-center"
      @click="isDealsOpen = !isDealsOpen"
    >
      <span
        class="d-flex"
        style="transform: rotate(-90deg); line-height: 10px;"
      >
        <span
          class="brand-logo brand-text flex-row"
          style="color: #fff; font-weight: 600; letter-spacing: 0.01rem; font-size: 1.0rem"
        >
          DEK
        </span>
        <span
          class="brand-logo brand-text flex-row"
          style="padding-left:5px; color: #fcb737; font-weight: 600; letter-spacing: 0.01rem; font-size: 1.0rem; font-family: 'Libre Franklin', sans-serif;"
        >
          Deals
        </span>
      </span>
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="deals-section d-flex justify-content-between align-items-center">
      <div>
        <h4 class="text-uppercase mb-0">
          <span
            class="brand-logo brand-text flex-row"
            style="color: #352a81; font-weight: 800; letter-spacing: 0.01rem; font-size: 1.7rem"
          >
            DEK
          </span>
          <span
            class="brand-logo brand-text flex-row"
            style="padding-left:5px; color: #fcb737; font-weight: 600; letter-spacing: 0.01rem; font-size: 1.5rem; font-family: 'Libre Franklin', sans-serif;"
          >
            Deals
          </span>
        </h4>
        <small>Get the greatest deals here!</small>
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="isDealsOpen = !isDealsOpen"
      />
    </div>
    <!-- Header -->

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-deals-area scroll-area"
    >
      <!-- Deal1 -->
      <div class="deals-section">

        <div
          v-for="(deal, index) in deals"
          :key="index"
        >
          <b-img
            :src="deal.image"
            :alt="deal.description"
            :width="`${deal.width}px`"
            :height="`${deal.height}px`"
            style="border: 1px solid #000"
          />
          <br>
          <b>{{ deal.name }}</b>
          {{ deal.description }}
        </div>

      </div>
      <!-- /Deal1 -->

    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BLink,
  BImg,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAppDeals from './useAppDeals'

export default {
  components: {
    // BSV
    BLink,
    BImg,

    // 3rd party
    VuePerfectScrollbar,
  },
  setup() {
    const {
      // Deals Open
      isDealsOpen,

      deals,
    } = useAppDeals()

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // Deals Open
      isDealsOpen,

      // Deals list
      deals,

      // Perfect Scrollbar
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.deals-section {
  padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

  .form-group {
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-deals-area {
  height: calc(100% - 83px)
}
</style>
