<template>
  <div
    class="navbar-container d-flex content align-items-center"
    style="padding-top: 0; padding-bottom: 0;"
  >

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          style="color: #fff; z-index: 2;"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="28"
          />
        </b-link>
      </li>
    </ul>

    <!-- Logo & Text -->
    <div
      class="navbar-background"
      style="padding-left: 5px;"
    >
      <b-link to="/">
        <span
          class="brand-logo brand-text flex-row"
          style="color: #fff; font-weight: 600; letter-spacing: 0.01rem; font-size: 1.6rem"
        >
          {{ appName }}
        </span>
        <span
          class="brand-logo brand-text flex-row"
          style="color: #fcb737; font-weight: 600; letter-spacing: 0.01rem; font-size: 1.4rem; font-family: 'Libre Franklin', sans-serif;"
        >
          {{ category }}
        </span>
      </b-link>
    </div>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- Empty -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <template v-if="isLoggedIn">
        <notification-dropdown />
        <user-dropdown />
      </template>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'

import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'

import { $themeConfig } from '@themeConfig'
import { isUserLoggedIn } from '@/auth/utils'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    SearchBar,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, context) {
    // App Name
    const { appName, appNameSecond, appLogoImage } = $themeConfig.app
    const isLoggedIn = isUserLoggedIn()

    // function titleCase(string) {
    //   return string[0].toUpperCase() + string.slice(1).toLowerCase()
    // }

    const category = computed(() => {
      let cat = context.root.$route.meta.pageTitle
      if (cat === undefined || cat === '') {
        cat = 'Sports'
      }
      return cat
    })

    return {
      appName,
      appNameSecond,
      appLogoImage,
      isLoggedIn,
      category,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
@import "https://fonts.googleapis.com/css2?family=Libre+Franklin";
</style>
