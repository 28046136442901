<template>
  <li class="dropdown nav-item">
    <b-link
      class="nav-link d-flex align-items-center"
      :to="{ name: item.route }"
    >
      <feather-icon
        v-if="item.iconSource === undefined || item.iconSource === 'default'"
        :icon="item.icon || 'CircleIcon'"
      />
      <sports-icon
        v-else-if="item.iconSource === 'sports'"
        :icon="item.icon || 'CircleIcon'"
      />
      <b-img
        v-else-if="item.iconSource === 'image'"
        class="feather feather-user"
        :src="item.icon"
        width="30px"
        style="padding-right: 10px; flex-shrink: 0; filter: invert(100%);"
      />
    </b-link>
  </li>
</template>

<script>
import { BImg, BLink } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BLink,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {}
  },
}
</script>
