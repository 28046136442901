import { ref } from '@vue/composition-api'

export default function useSlideMenu() {
  // Slide Menu
  const isSlideMenuOpen = ref(false)

  return {
    // Slide Menu
    isSlideMenuOpen,
  }
}
