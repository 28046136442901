import { ref } from '@vue/composition-api'
import deals from '@deals'

export default function useAppDeals() {
  // Deals
  const isDealsOpen = ref(false)

  return {
    // Deals
    isDealsOpen,

    // Content
    deals,
  }
}
