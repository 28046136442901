<template>
  <div
    class="navbar-container main-menu-content"
    style="background: none;"
  >
    <div class="flex-container">
      <a
        v-b-modal.modal-scrollable
      >
        <feather-icon
          icon="HeartIcon"
          size="20"
          badge="+"
          style="color: white;"
          fill="white"
          badge-classes="badge-secondary badge-small"
        />
      </a>
      <span style="padding-left: 18px; margin-right: 10px; border-right: 3px solid gray;" />
      <horizontal-sport-menu-items
        class="flex-child"
        :items="sportMenuItems"
      />
    </div>
  </div>
</template>

<script>
import {
  VBModal,
} from 'bootstrap-vue'

import sportMenuItems from '@/navigation/vertical/sports'
import HorizontalSportMenuItems from './components/horizontal-sport-menu-items/HorizontalSportMenuItems.vue'

export default {
  components: {
    HorizontalSportMenuItems,
  },

  directives: {
    'b-modal': VBModal,
  },

  setup() {
    return {
      sportMenuItems,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";

.flex-container {
  display: inline-flex;
}

.flex-child {
  flex-grow: 1;
  flex-shrink: 0;
}

.flex-child:first-child {
  margin-right: 20px;
}

.badge-small {
  min-width: 1rem;
  min-height: 1rem;
  padding: 0.13rem 0.15rem;
  right: -8px;
  top: -8px;
}
</style>
