<template>
  <div
    class="slidemenu d-block  slidemenu-bg"
    :class="{'open': isSlideMenuOpen}"
  >
    <!-- Toggler -->
    <b-link
      class="slidemenu-toggle d-flex align-items-center justify-content-center"
      @click="isSlideMenuOpen = !isSlideMenuOpen"
    >
      <feather-icon
        icon="MenuIcon"
        size="20"
      />
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="slidemenu-section d-flex justify-content-between align-items-center">
      <div>
        <ul
          id="main-menu-navigation"
          class="nav navbar-nav"
          style="flex-direction: row; align-items: center; justify-content: center; padding-left: 10px;"
        >
          <li
            v-for="item in sportMenuItems"
            :key="item.header || item.title"
            class="nav-item"
            style="display: inline-block;"
          >
            <b-link
              class="nav-link"
              :to="{ name: item.route }"
            >
              <b-img
                v-if="item.iconSource === 'image'"
                class="feather feather-user"
                :src="item.icon"
                width="36px"
                style="padding-right: 10px; flex-shrink: 0; filter: invert(100%);"
              />
            </b-link>
          </li>
        </ul>
      </div>

      <feather-icon
        icon="XIcon"
        size="48"
        class="cursor-pointer"
        style="padding-right: 15px; color: white;"
        @click="isSlideMenuOpen = !isSlideMenuOpen"
      />
    </div>
    <!-- Header -->

  </div>
</template>

<script>
import {
  BLink,
  BImg,
} from 'bootstrap-vue'

import sportMenuItems from '@/navigation/vertical/sports'
import useSlideMenu from './useSlideMenu'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    const {
      // Slide Menu Open
      isSlideMenuOpen,
    } = useSlideMenu()

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // Slide Menu Open
      isSlideMenuOpen,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Sports menu items
      sportMenuItems,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.slidemenu-bg {
  background: $secondary;
}

.slidemenu-section {
  height: 100%;

  .form-group {
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-slidemenu-area {
  height: calc(100% - 83px)
}
</style>
