export default [
  {
    name: 'Deal 1',
    description: 'Deal 1 Description',
    url: 'deksports.com',
    // eslint-disable-next-line global-require
    image: require('@/assets/images/logo/logo.png'),
    width: 160,
    height: 120,
  },

  {
    name: 'Deal 2a',
    description: 'Deal 2 Description',
    url: 'deksports.com',
    // eslint-disable-next-line global-require
    image: require('@/assets/images/logo/logo.png'),
    width: 200,
    height: 150,
  },

  {
    name: 'Deal 3',
    description: 'Deal 3 Description',
    url: 'deksports.com',
    // eslint-disable-next-line global-require
    image: require('@/assets/images/logo/logo.png'),
    width: 200,
    height: 100,
  },

  {
    name: 'Deal 4',
    description: 'Deal 4 Description',
    url: 'deksports.com',
    // eslint-disable-next-line global-require
    image: require('@/assets/images/logo/logo.png'),
    width: 100,
    height: 100,
  },

  {
    name: 'Deal 5',
    description: 'Deal 5 Description',
    url: 'deksports.com',
    // eslint-disable-next-line global-require
    image: require('@/assets/images/logo/logo.png'),
    width: 130,
    height: 100,
  },
]
